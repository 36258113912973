<template>
    <v-checkbox
      v-model="value"
      :label="label"
      :rules="rules"
      validate-on-blur
    ></v-checkbox>
    <!-- <input v-model="value" type="checkbox" /> -->
    <!-- <div class="control_indicator"></div> -->
</template>
<script>
export default {
  data() {
    return {
      value: false
    };
  },
  mounted() {
    this.$emit("input", false);
  },
  props: {
    label: {
      type: String
    },
    rules: {
      type: Array,
      default: () => ([])
    }
  },
  watch: {
    value: function(newVal) {
      this.$emit("input", newVal);
    }
  }
};
</script>
<style lang="scss">
  .v-input--selection-controls {
    margin-top: 0;
  }
</style>
