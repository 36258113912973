<template>
  <simplebar class="consultation-popup" data-simplebar-auto-hide="false">
    <section class="form">
      <inline-svg
        :src="require('@/assets/map/ic_close.svg')"
        @click="$emit('close-popup')"
        class="icon close"
        alt="icone fermeture"
      ></inline-svg>
      <v-stepper v-model="step">
        <v-stepper-items>
          <v-stepper-content step="1" class="step1">
            <section class="header pt-6 px-6">
              <!-- <h1 class="popup-title mb-4">{{ $t("consultation.title") }}</h1>
              <p class="popup-p">{{ $t("consultation.p") }}</p> -->
              <div v-html="consultation.popup_header"></div>
              <hr />
              <h1 class="popup-title mt-4 mb-4">
                {{ $t("consultation.label_selected_address") }}
              </h1>

              <p v-if="is_free_choice">{{ poi.data.place_name }}</p>
              <div v-else class="editAddress">
                <!-- <v-btn
                  @click="isSuggesting = !isSuggesting"
                  class="mr-5"
                  color="primary"
                  elevation="2"
                  fab
                  icon
                  x-small
                >
                  <img
                    :src="
                      isSuggesting
                        ? require('@/assets/map/ic_back_arrow.webp')
                        : require('@/assets/map/ic_edit.svg')
                    "
                    width="20px"
                    alt="edit"
                  />
                </v-btn> -->
                <p v-if="!isSuggesting">{{ poi.data.place_name }}</p>
                
              </div>

              <p
                class="mb-4 error-message"
                v-if="poi && !poi.isPrecise && !isSuggesting"
              >
                {{ $t("consultation.check_address_precision") }}
              </p>
            </section>
            <v-card>
              <v-form ref="formStep1" v-on:submit.prevent="validateFirstStep">
                <p class="pl-6 mb-0 text-uppercase popup-label mb-2">
                  {{ $t("consultation.identity") }}
                </p>
                <v-radio-group
                  class="consultation-type mt-0 pl-4"
                  v-model="consultation_response.identity"
                  row
                  :rules="requiredRule"
                >
                  <v-radio
                    value="particulier"
                    class="mb-2"
                    :label="$t('consultation.individual')"
                  ></v-radio>
                  <v-radio
                    value="collectivité"
                    class="mb-2"
                    :label="$t('consultation.collectivity')"
                  ></v-radio>
                  <v-radio
                    value="entreprise"
                    class="mb-2"
                    :label="$t('consultation.business')"
                  ></v-radio>
                </v-radio-group>
                <section class="px-6">
                  <v-text-field
                    class="mt-0"
                    v-model="consultation_response.lastname"
                    :rules="requiredRule"
                    :label="$t('consultation.lastname')"
                  ></v-text-field>
                  <v-text-field
                    v-model="consultation_response.firstname"
                    :rules="requiredRule"
                    :label="$t('consultation.firstname')"
                  ></v-text-field>
                  <v-text-field
                    type="email"
                    v-model="consultation_response.email"
                    :rules="requiredRule"
                    label="Email *"
                  ></v-text-field>
                  <v-text-field
                    v-if="consultation_response.identity === 'entreprise'"
                    v-model="consultation_response.company"
                    :rules="requiredRule"
                    :label="$t('consultation.company')"
                  ></v-text-field>
                  <v-text-field
                    v-model="consultation_response.address.value"
                    :rules="requiredRule"
                    ref="consultation-address-input"
                    :label="$t('consultation.adress')"
                  ></v-text-field>
                  <!-- {{consultation}} -->
                  <v-text-field
                    v-if="!(consultation.phone_restriction === 2)"
                    class="mb-2"
                    v-model="consultation_response.phone"
                    :rules="consultation.phone_restriction === 0 ? requiredRule : []"
                    :label="$t('consultation.phone')"
                  ></v-text-field>
                </section>

                <v-btn
                  color="#5886CD"
                  dark
                  class="text-uppercase"
                  type="submit"
                  large
                  tile
                  block
                  ><img
                    width="20"
                    class="mr-3"
                    src="@/assets/map/ic_next.svg"
                    alt=">"
                  />{{ $t("consultation.continue") }}</v-btn
                >
              </v-form>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2" class="step2">
            <v-card>
              <section class="back d-flex align-center pl-4 pt-2">
                <img
                  src="@/assets/map/ic_back_arrow.webp"
                  class="back-arrow"
                  @click="back"
                  alt=""
                />
                <span class="mx-2">Étape précédente</span>
              </section>
              <v-form ref="formStep2" @submit.prevent="validateSecondStep">
                <p class="mb-0 pt-2 px-4 popup-label text-uppercase mb-2 mt-4">
                  {{ $t("consultation.proximity") }}
                </p>
                <v-radio-group
                  :rules="requiredRule"
                  class="consultation-type mt-0 pl-4"
                  v-model="consultation_response.location"
                  row
                >
                  <v-radio
                    value="domicile"
                    v-bind:label="$t('consultation.domicile')"
                  ></v-radio>
                  <v-radio
                    value="travail"
                    v-bind:label="$t('consultation.work')"
                  ></v-radio>
                  <v-radio
                    value="autre"
                    v-bind:label="$t('consultation.other')"
                  ></v-radio>
                </v-radio-group>
                <section class="px-4">
                  <v-text-field
                    v-if="consultation_response.location == 'autre'"
                    v-model="consultation_response.location_complement"
                    :label="$t('consultation.specify')"
                  ></v-text-field>
                  <p class="mb-0 popup-label text-uppercase mb-2">
                    {{ $t("consultation.usage") }}
                  </p>
                  <v-radio-group
                    :rules="requiredRule"
                    class="consultation-type mt-0"
                    v-model="consultation_response.usage_time"
                    row
                  >
                    <v-radio
                      value="jour"
                      :label="$t('consultation.day')"
                    ></v-radio>
                    <v-radio
                      value="nuit"
                      :label="$t('consultation.night')"
                    ></v-radio>
                  </v-radio-group>
                  <p v-if="!is_free_choice && consultation.has_custom_address" class="mb-0 popup-p mb-2">
                    {{ $t("consultation.none") }}
                  </p>
                  <v-text-field
                    v-if="!is_free_choice && consultation.has_custom_address"
                    ref="custom-address-input"
                    v-model="consultation_response.custom_address.value"
                    :label="$t('consultation.custom_address')"
                  ></v-text-field>

                  <Checkbox
                    v-if="consultation.parking"
                    v-model="consultation_response.hasNoParking"
                    :label="consultation.parking"
                  />
                    <!-- :label="$t('consultation.noParking')" -->
                  <Checkbox
                    v-if="consultation.recharge_point"
                    v-model="consultation_response.hasNoRechargePoint"
                    :label="consultation.recharge_point"
                  />
                    <!-- :label="$t('consultation.noRechargePoint')" -->
                  <Checkbox
                    v-if="consultation.electric_car_buyed"
                    v-model="consultation_response.hasElectricCarBuyed"
                    :label="consultation.electric_car_buyed"
                  />
                  <Checkbox
                    v-if="consultation.electric_car_ordered"
                    v-model="consultation_response.hasElectricCarOrdered"
                    :label="consultation.electric_car_ordered"
                  />
                    <!-- :label="$t('consultation.hasElectricCar')" -->
                    
                  
                </section>

                <v-btn
                  color="#5886CD"
                  class="text-uppercase"
                  type="submit"
                  large
                  tile
                  dark
                  block
                  ><img
                    width="20"
                    class="mr-3"
                    src="@/assets/map/ic_validate.svg"
                    alt="validate"
                  />{{ $t("consultation.continue2") }}</v-btn
                >
                
              </v-form>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="3" class="step3">
            <v-card>
              <section class="back d-flex align-center pl-4 pt-2">
                <img
                  src="@/assets/map/ic_back_arrow.webp"
                  class="back-arrow"
                  @click="back"
                  alt=""
                />
                <span class="mx-2">Étape précédente</span>
              </section>
              <v-form ref="formStep3" @submit.prevent="handleSubmit">
                <section class="data-summary d-flex flex-column px-4 pt-4">
                  <span>Vous êtes : {{consultation_response.identity}}</span>
                  <span>Nom : {{consultation_response.lastname}}</span>
                  <span>Prénom : {{consultation_response.firstname}}</span>
                  <span>Email : {{consultation_response.email}}</span>
                  <span v-if="consultation_response.phone">Téléphone : {{consultation_response.phone}}</span>
                  <span v-if="consultation_response.company">Société : {{consultation_response.company}}</span>
                  <span>Adresse : {{consultation_response.address.value}}</span>
                  <span>Emplacement : {{consultation_response.location}}</span>
                  <span>Utilisation : {{consultation_response.usage_time}}</span>
                  <span v-if="consultation_response.hasElectricCarBuyed !== null">Possède une voiture électrique : {{consultation_response.hasElectricCarBuyed ? 'Oui' : 'Non'}}</span>
                  <span v-if="consultation_response.hasElectricCarOrdered !== null">A commandé une voiture électrique : {{consultation_response.hasElectricCarOrdered ? 'Oui' : 'Non'}}</span>
                  <span v-if="consultation_response.hasNoParking !== null">Possède une place de parking : {{consultation_response.hasNoParking ? 'Non': 'Oui'}}</span>
                  <span v-if="consultation_response.hasNoRechargePoint !== null">Possède un point de charge : {{consultation_response.hasNoRechargePoint ? 'Non': 'Oui'}}</span>
                </section>
                <div class="rgpd-data px-4 pt-2" v-html="consultation.rgpd"></div>
                <div class="rgpd-container d-flex mt-8 px-4">
                  
                  <Checkbox
                    class="justify-center mr-4"
                    v-model="consultation_response.rgpd"
                    :rules="requiredRule"
                  />
                  <div v-html="consultation.conditions"></div>
                </div>
                  
                <div class="rgpd-container d-flex px-4">
                  <Checkbox
                    class="justify-center mr-4"
                    v-model="consultation_response.honor"
                    :rules="requiredRule"
                  />
                    <!-- :label="$t('consultation.rgpd')" -->
                  
                  <div v-html="consultation.honor"></div> 
                </div>

                <!-- <div class="rgpd-container documents d-flex px-4">
                  <div v-html="consultation.documents"></div> 
                </div> -->

                

                <v-btn
                  color="#5886CD"
                  class="text-uppercase"
                  type="submit"
                  large
                  tile
                  dark
                  block
                  ><img
                    width="20"
                    class="mr-3"
                    src="@/assets/map/ic_validate.svg"
                    alt="validate"
                  />{{ $t("consultation.validate") }}</v-btn
                >
              </v-form>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="4" class="step4">
            <v-card class="py-8 px-4">
              <div v-html="consultation.documents"></div> 
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </section>
  </simplebar>
</template>

<script>
import Checkbox from "@/components/checkbox.vue";
export default {
  components: {
    Checkbox
  },
  data: () => ({
    idConsultation: {},
    selectedAddress: {},
    consultation_response: {
      id_selected_poi: null,
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      address: {},
      custom_address: {},
      company: "",
      location: "",
      location_complement: "",
      honor: false,
      rgpd: false,
      hasNoParking: null,
      hasNoRechargePoint: null,
      hasElectricCarBuyed: null,
      hasElectricCarOrdered: null
    },
    isSuggesting: false,
    loading: true,
    step: 1,
    requiredRule: [v => !!v || "Ce champ est obligatoire"],
    autocomplete_user: null,
    autocomplete_suggestion: null,
  }),
  mounted() {
    this.autocomplete_user = new window.google.maps.places.Autocomplete(
      this.$refs["consultation-address-input"].$el.querySelector("input")
    );
    this.autocomplete_user.setComponentRestrictions({
      country: ["fr"]
    });
    this.autocomplete_user.addListener("place_changed", () => {
      let address = {
        geometry: {
          coordinates: [
            this.autocomplete_user.getPlace().geometry.location.lng(),
            this.autocomplete_user.getPlace().geometry.location.lat()
          ],
          crs: {
            type: "name",
            properties: {
              address: this.autocomplete_user.getPlace().formatted_address
            }
          },
          type: "Point"
        }
      };
      address.value = this.autocomplete_user.getPlace().formatted_address;
      this.consultation_response.address = address;
    });
    if (!this.is_free_choice && this.consultation.has_custom_address) {
      this.autocomplete_suggestion = new window.google.maps.places.Autocomplete(
        this.$refs["custom-address-input"].$el.querySelector("input")
      );
      this.autocomplete_suggestion.setComponentRestrictions({
        country: ["fr"]
      });
      this.autocomplete_suggestion.addListener("place_changed", () => {
        let address = {
          geometry: {
            coordinates: [
              this.autocomplete_suggestion.getPlace().geometry.location.lng(),
              this.autocomplete_suggestion.getPlace().geometry.location.lat()
            ],
            crs: {
              type: "name",
              properties: {
                address: this.autocomplete_suggestion.getPlace()
                  .formatted_address
              }
            },
            type: "Point"
          }
        };
        address.value = this.autocomplete_suggestion.getPlace().formatted_address;
        this.consultation_response.custom_address = address;
      });
    }
  },
  methods: {
    async handleSubmit() {
      if(!(this.$refs.formStep3.validate() && this.step == 3)) return
      this.consultation_response.poi = {
        id: this.poi.id,
        data: {
          geometry: this.poi.data.geometry,
          properties: {
            address: this.poi.data.place_name
          }
        },
        is_free_choice: this.is_free_choice
      };

      this.consultation_response.id_consultation = this.$route.params.id
      if (this.is_free_choice) {
        await this.$store.dispatch(
          "consultation/newResponseFree",
          this.consultation_response
        );
      } else {
        if (!this.consultation_response.custom_address && !this.consultation_response.custom_address.value)
          this.consultation_response.custom_address = null;
        await this.$store.dispatch(
          "consultation/newResponsePredef",
          this.consultation_response
        );
      }

      this.step = 4
    },

    validateFirstStep() {
      if (this.$refs.formStep1.validate() && this.step == 1) {
        this.$store
          .dispatch("consultation/findUserInResponses", {
            mail: this.consultation_response.email,
            id: this.$route.params.id
          })
          .then(response => {
            if (response.length) {
              this.$toast.info(
                "Vous avez déjà participé à cette consultation. Nous vous en remercions.",
                {
                  position: "top-center",
                  timeout: 2794,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: false,
                  draggablePercent: 1.92,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: false,
                  icon: true,
                  rtl: false
                }
              );
            } else {
              this.step = 2;
            }
          });
      }
    },
    validateSecondStep() {
      if (this.$refs.formStep2.validate() && this.step === 2) {
        this.step = 3
      }
    },
    back() {
      if(this.step > 1) this.step = this.step - 1
    }
  },
  props: {
    poi: {
      type: Object,
      default: null
    },
    consultation: {
      type: Object,
      default: () => ({})
    },
    is_free_choice: {
      type: Boolean,
      default: null
    }
  }
};
</script>

<style lang="scss">
.consultation-popup {
  max-height: 77vh;
  overflow: auto;
  width: 100%;
  display: block;
  z-index: 2;
  background: white;
  position: relative;
  border-radius: 3px;
  padding-top: 10px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    width: 15px;
    // font-size: 30px;
    z-index: 3;
  }
  .error-message {
    color: indianred;
    font-weight: bold;
  }
  .consultation-type {
    .v-input {
      margin-bottom: 0.8em;
    }
    .v-label {
      background: rgba(231, 231, 231, 0.884);
      padding: 0.7em;
      font-size: 0.8em;
    }
    .v-item--active {
      .v-label {
        background: rgba(143, 143, 143, 0.884);
      }
    }
    .v-input--selection-controls__input {
      display: none;
    }
  }
  .popup-title {
    font-size: 18px;
    line-height: 20px;
  }
  .popup-p {
    font-size: 14px;
    line-height: 16px;
  }
  .popup-label {
    font-size: 10px;
  }
  .step2, .step3 {
    .v-label {
      font-size: 14px;
      line-height: 16px;
      color: black;
    }
    .back-arrow {
      cursor: pointer;
      width: 20px;
    }
  }
  .v-radio {
    label {
      border-radius: 3px;
    }
    margin-right: 8px !important;
  }
  .v-stepper__content {
    padding: 0;
  }

  .v-stepper,
  .v-stepper__header {
    box-shadow: none;
    border-radius: 0;
  }
  .v-card {
    box-shadow: none;
  }
  .editAddress {
    display: flex;
  }
  .selectedAdress {
    width: 100%;
    resize: none;
    outline: none;
  }
}
.rgpd-data {
  margin: 2em 0;
  font-size: .75em;
}
.rgpd-container.documents {
  font-size: .75em;
}
</style>
