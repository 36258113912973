<template>
  <section class="consultation">
    <MapConsultation
      :currentConsultation="currentConsultation"
      v-if="!isLoading && currentConsultation"
    ></MapConsultation>
    <div v-if="isLoading" class="loader">
      <v-progress-circular indeterminate color="#59b570"></v-progress-circular>
    </div>
  </section>
</template>

<script>
import MapConsultation from "@/components/map/MapConsultation";
export default {
  components: { MapConsultation },
  data() {
    return {
      currentConsultation: null
    };
  },
  async created() {
    this.currentConsultation = await this.$store.dispatch(
      "consultation/getOneConsultation",
      this.$route.params.id
    );

    await this.$store.dispatch(
      "station/getStationsByCPOsID",
      this.currentConsultation.displayed_cpos
    );
  },
  computed: {
    isLoading() {
      return this.$store.getters["station/isStationLoading"];
    }
  }
};
</script>

<style lang="scss">
.consultation {
  height: 100vh;
  width: 100%;
  background: white;
  z-index: 15;
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    background: #2c2c32;
    width: 100%;
    height: 100%;
    .v-progress-circular {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
