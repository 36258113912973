<template>
  <section class="map-consultation">
    <div class="left">
      <div class="search">
        <Search
          v-if="isMapLoaded"
          @google-marker="setGoogleMarker"
          :map="$refs['consultation-map']"
          blue-marker
          :draw="currentConsultation.is_free_choice"
        ></Search>

        <v-expansion-panels
          v-model="panel"
          v-if="currentConsultation.header && !markerData"
          class="mb-4"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              Introduction
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <simplebar class="introduction" data-simplebar-auto-hide="false">
                <div
                  class="px-4 header-message"
                  v-html="currentConsultation.header"
                ></div>
                <v-btn class="ma-auto d-block mb-4" @click="panel = null"
                  >Ok</v-btn
                >
              </simplebar>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-if="!markerData && !(panel === 0)" class="message">
          {{
            currentConsultation.is_free_choice
              ? $t("consultation.message_free_choice")
              : $t("consultation.message_suggested_places")
          }}
        </div>
      </div>
      <ConsultationPopup
        v-if="markerData"
        :poi="markerData"
        :is_free_choice="currentConsultation.is_free_choice"
        :consultation="currentConsultation"
        @sent="handleSent"
        @close-popup="markerData = null"
      ></ConsultationPopup>
    </div>
    <MglMap
      v-if="consultationLoaded"
      ref="consultation-map"
      :mapStyle.sync="mapStyle"
      :accessToken="accessToken"
      @load="mapLoaded"
      @click="showMapConsultationMarker"
      :center="center"
      :zoom="zoom"
      :pitch="pitch"
    >
      <MglGeojsonLayer
        :source="geoJsonSource"
        sourceId="stations"
        layerId="clusters"
        :layer="clusters"
        @click="clusterZoom"
        @mouseenter="e => cursor(e, 'pointer')"
        @mouseleave="e => cursor(e, 'grab')"
      />
      <MglGeojsonLayer
        :source="geoJsonSource"
        sourceId="stations"
        layerId="cluster-count"
        :layer="count"
      />
      <MglGeojsonLayer
        :source="geoJsonSource"
        sourceId="stations"
        layerId="unclustered-point"
        :layer="unclustered"
      />
      <MglGeojsonLayer
        :source="geoJSONPredefinis"
        sourceId="predefmark"
        layerId="clusters-predef-mark"
        :layer="clustersPredefMark"
        @click="clusterZoom"
        @mouseenter="e => cursor(e, 'pointer')"
        @mouseleave="e => cursor(e, 'initial')"
      />
      <MglGeojsonLayer
        :source="geoJSONPredefinis"
        sourceId="predefmark"
        layerId="cluster-predef-mark-count"
        :layer="countPredefMark"
      />
      <MglGeojsonLayer
        :source="geoJSONPredefinis"
        sourceId="predefmark"
        layerId="predef-mark"
        :layer="predefMark"
        v-if="!currentConsultation.is_free_choice"
        @mouseenter="e => cursor(e, 'pointer')"
        @mouseleave="e => cursor(e, 'grab')"
        @click="
          e => {
            handlePredef(e);
          }
        "
      />
      <MglGeojsonLayer
        :source="geoJSONResponses"
        sourceId="responsemark"
        layerId="response-mark"
        :layer="responseMark"
        v-if="currentConsultation.is_free_choice"
      />
      <MglGeojsonLayer
        v-if="
          currentConsultation.restricted_area &&
            currentConsultation.restricted_area.length
        "
        :source="restrictedAreaSource"
        sourceId="restricted-area"
        layerId="restricted-area-fill"
        :layer="restrictedAreaFill"
        @mouseenter="() => setRestrictedAreaHover(true)"
        @mouseleave="() => setRestrictedAreaHover(false)"
      />
      <MglGeojsonLayer
        v-if="
          currentConsultation.restricted_area &&
            currentConsultation.restricted_area.length
        "
        :source="restrictedAreaSource"
        sourceId="restricted-area"
        layerId="restricted-area"
        :layer="restrictedArea"
        @mouseenter="() => setRestrictedAreaHover(true)"
        @mouseleave="() => setRestrictedAreaHover(false)"
      />
      <MglNavigationControl position="bottom-right" />
      <MglGeolocateControl position="bottom-right" :trackUserLocation="true" />
    </MglMap>
  </section>
</template>
<script>
import pin from "@/assets/map/pin-sdf.png";
import pin_predef from "@/assets/map/pin-sdf.png";
import {
  MglMap,
  MglGeojsonLayer,
  MglNavigationControl,
  MglGeolocateControl
  // MglGeojsonLayer
} from "vue-mapbox";
import Mapbox from "mapbox-gl";
import Search from "@/components/map/filter/Search";
import ConsultationPopup from "@/components/map/ConsultationPopup";
import LangSwitch from "@/components/LangSwitch";
import axios from "axios";
import * as turf from "@turf/turf";
export default {
  props: ["currentConsultation"],
  components: {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    Search,
    ConsultationPopup,
    LangSwitch,
    MglGeojsonLayer
  },
  data: () => ({
    panel: 0,
    isMounted: false,
    accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
    mapStyle: "mapbox://styles/mapbox/streets-v11",
    center: [2.2, 46.2],
    zoom: 6,
    pitch: 40,
    map: null,
    markerData: null,
    isMapLoaded: false,
    isClusterHover: false,
    consultationLoaded: false,
    filterCPO: [],
    isRestrictedAreaHover: false,
    clusters: {
      id: "clusters",
      type: "circle",
      filter: ["has", "point_count"],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        "circle-color": "rgba(89, 89, 89, 0.5)",
        "circle-radius": ["step", ["get", "point_count"], 15, 10, 22, 30, 34]
      }
    },
    count: {
      id: "cluster-count",
      type: "symbol",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 14,
        "text-allow-overlap": true
      },
      paint: {
        "text-color": "#ffffff"
      }
    },
    unclustered: {
      id: "unclustered-point",
      layout: {
        "icon-image": "pin",
        "icon-padding": 5,
        "icon-size": {
          stops: [
            [0, 0.3],
            [9, 0.3],
            [12, 0.4],
            [15, 0.5]
          ]
        },
        "icon-rotate": 0,
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
        "icon-ignore-placement": true
      },

      type: "symbol",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "icon-color": [
          "case",
          ["in", "FREE", ["get", "status"]],
          "#59b570",
          ["in", "AVAILABLE", ["get", "status"]],
          "#59b570",
          ["in", "IN_USE", ["get", "status"]],
          "#1f73b4",
          ["in", "BLOCKED", ["get", "status"]],
          "#1f73b4",
          ["in", "RESERVED", ["get", "status"]],
          "#1f73b4",
          ["in", "CHARGING", ["get", "status"]],
          "#1f73b4",
          "rgba(89, 89, 89, 0.5)"
        ]
      }
    },
    restrictedArea: {
      id: "restricted-area",
      type: "line",
      layout: {},
      paint: {
        "line-color": "black",
        "line-opacity": 0.3,
        "line-width": 3,
        "line-dasharray": [2, 2, 2, 2]
      }
    },
    restrictedAreaFill: {
      id: "restricted-area-fill",
      type: "fill",
      layout: {},
      paint: {
        "fill-color": "transparent"
      }
    },
    clustersPredefMark: {
      id: "clusters-predef-mark",
      type: "circle",
      filter: ["has", "point_count"],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        "circle-color": "#5886CD",
        "circle-radius": ["step", ["get", "point_count"], 15, 10, 22, 30, 34]
      }
    },
    countPredefMark: {
      id: "cluster-predef-mark-count",
      type: "symbol",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 14,
        "text-allow-overlap": true
      },
      paint: {
        "text-color": "#ffffff"
      }
    },
    predefMark: {
      id: "predef-mark",
      type: "symbol",
      layout: {
        "icon-image": "pin_predef",
        "icon-padding": 5,
        "icon-size": {
          stops: [
            [0, 0.3],
            [9, 0.3],
            [12, 0.4],
            [15, 0.5]
          ]
        },
        "icon-rotate": 0,
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
        "icon-ignore-placement": true
      },
      filter: ["!", ["has", "point_count"]],
      paint: {
        "icon-color": " #5886CD"
      }
    },
    responseMark: {
      id: "response-mark",
      type: "symbol",
      layout: {
        "icon-image": "pin_predef",
        "icon-size": {
          stops: [
            [0, 0.3],
            [9, 0.3],
            [12, 0.4],
            [15, 0.5]
          ]
        }
      },
      paint: {
        "icon-color": " #6d071a"
      }
    },
    geo_points_list: [],
    responseStations: []
  }),
  created() {
    this.mapbox = Mapbox;
  },
  async mounted() {
    this.consultationLoaded = true;
    if (!this.currentConsultation) return this.$router.push("/");
    if (this.currentConsultation.is_free_choice) {
      let responseStations = await this.$store.dispatch(
        "consultation/getConsultationResponseStations",
        this.$route.params.id
      );
      if (responseStations.length)
        this.responseStations.geo_points_list = responseStations.map(
          station => station.data
        );
    }
    this.filterCPO = this.currentConsultation.displayed_cpos;
    document.title =
      "Alizé Charge - Consultation : " + this.currentConsultation.name;
    this.currentConsultation.consultation_stations.forEach(station => {
      station.data.id = station.id;
      this.geo_points_list.push(station.data);
    });
  },
  computed: {
    stations() {
      return this.$store.getters["station/stations"];
    },
    formatedJSON() {
      let jsonObj = {
        type: "FeatureCollection",
        features: this.stations
      };
      return jsonObj;
    },
    geoJSONPredefinis() {
      return {
        type: "geojson",
        data: { type: "FeatureCollection", features: this.geo_points_list },
        cluster: true,
        clusterMaxZoom: 11, // Max zoom to cluster points on
        clusterRadius: 100 // Radius of each cluster when clustering points (defaults to 50)
      };
    },
    geoJSONResponses() {
      return {
        type: "geojson",
        data: { type: "FeatureCollection", features: this.geo_points_list },
        cluster: true,
        clusterMaxZoom: 11, // Max zoom to cluster points on
        clusterRadius: 100 // Radius of each cluster when clustering points (defaults to 50)
      };
    },
    geoJsonSource() {
      return {
        type: "geojson",
        data: this.formatedJSON,
        cluster: true,
        clusterMaxZoom: 11, // Max zoom to cluster points on
        clusterRadius: 100 // Radius of each cluster when clustering points (defaults to 50)
      };
    },
    restrictedAreaSource() {
      return {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [this.currentConsultation.restricted_area]
          }
        }
      };
    }
  },
  methods: {
    handleSent() {
      this.markerData = null;
      // this.$toast.success("Votre réponse a bien été envoyée. \n" + this.currentConsultation.documents, {
      //   position: "center-center",
      //   timeout: 2794,
      //   closeOnClick: true,
      //   pauseOnFocusLoss: true,
      //   pauseOnHover: true,
      //   draggable: false,
      //   draggablePercent: 1.92,
      //   showCloseButtonOnHover: false,
      //   hideProgressBar: true,
      //   closeButton: false,
      //   icon: true,
      //   rtl: false
      // });
    },
    async setAddress(coords, id, custom) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
              coords[0] +
              "," +
              coords[1] +
              ".json?access_token=" +
              process.env.VUE_APP_MAPBOX_TOKEN
          )
          .then(response => {
            if (
              response.data.features.find(item =>
                item.place_type.includes("poi")
              )
            ) {
              resolve({
                data: response.data.features.filter(item =>
                  item.place_type.includes("poi")
                )[0],
                id: id,
                isPrecise: custom ? false : true
              });
            } else {
              if (
                response.data.features.find(item =>
                  item.place_type.includes("address")
                )
              ) {
                resolve({
                  isPrecise: true,
                  id: id,
                  data: response.data.features.find(item =>
                    item.place_type.includes("address")
                  )
                });
              } else {
                if (
                  response.data.features.find(item =>
                    item.place_type.includes("place")
                  )
                ) {
                  resolve({
                    isPrecise: custom ? false : true,
                    id: id,
                    data: response.data.features.find(item =>
                      item.place_type.includes("place")
                    )
                  });
                } else {
                  reject(response);
                }
              }
            }
          })
          .catch(e => reject(e));
      });
    },
    async handlePredef(e) {
      let data = e.component.getRenderedFeatures(e.mapboxEvent.point)[0];
      this.markerData = await this.setAddress(
        [data.geometry.coordinates[0], data.geometry.coordinates[1]],
        data.id
      );
    },
    mapLoaded(e) {
      e.map.loadImage(pin, (error, image) => {
        if (error) throw error;
        e.map.addImage("pin", image, { sdf: true });
      });
      e.map.loadImage(pin_predef, (error, image) => {
        if (error) throw error;
        e.map.addImage("pin_predef", image, { sdf: true });
      });
      this.isMapLoaded = true;
    },
    showCustomMarker(e) {
      if (this.isClusterHover) return;
      if (!this.geo_points_list.length) {
        this.removeMarker();
        let { lng, lat } = e.mapboxEvent.lngLat;
        this.createMarker([lng, lat]);
      }
    },
    async createMarker(coord) {
      if (this.markerData != null) this.removeMarker();
      this.markerData = await this.setAddress(coord, null, false);
      var el = document.createElement("div");
      el.className = "marker";
      // var marker = new this.map.mapbox.Marker(el)
      new this.$refs["consultation-map"].mapbox.Marker(el)
        .setDraggable(true)
        .setLngLat(coord)
        .addTo(this.$refs["consultation-map"].map)
        .on("dragend", this.onDragEnd)
        .on("dragstart", () => {
          this.tempCoords = coord;
        });
    },
    async createDefinedMarker(coord) {
      var el = document.createElement("div");
      el.className = "marker";
      // var marker = new this.map.mapbox.Marker(el)
      el.addEventListener("click", async () => {
        this.markerData = await this.setAddress(coord);
      });

      new this.$refs["consultation-map"].mapbox.Marker(el)
        .setLngLat(coord)
        .addTo(this.$refs["consultation-map"].map);
    },
    async onDragEnd(e) {
      if (
        this.currentConsultation.restricted_area.length &&
        this.isRestrictedAreaHover
      ) {
        this.createMarker([e.target._lngLat.lng, e.target._lngLat.lat]);
      } else {
        this.removeMarker();
        this.createMarker(this.tempCoords);
      }
    },
    removeMarker() {
      let el = document.querySelector(".marker");
      if (!el) return;
      else {
        el.remove();
      }
    },
    async setGoogleMarker(coord) {
      if (
        this.currentConsultation.restricted_area &&
        this.currentConsultation.restricted_area.length
      ) {
        if (
          turf.booleanPointInPolygon(
            turf.point(coord),
            turf.polygon([this.currentConsultation.restricted_area])
          )
        ) {
          this.createMarker(coord);
        } else {
          this.$toast.error(
            "Veuillez choisir un emplacement dans la zone dessinée à l'écran",
            {
              position: "top-center",
              timeout: 2794,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: false,
              draggablePercent: 1.92,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false
            }
          );
        }
      } else {
        this.createMarker(coord);
      }
    },
    async clusterZoom(e) {
      // e.component represents the layer
      const features = e.component.getRenderedFeatures(e.mapboxEvent.point);
      const clusterId = features[0].id;
      e.component.getClusterExpansionZoom(clusterId).then(zoom => {
        this.centerElement(e, zoom);
      });
    },
    cursor(e, style) {
      if (style === "pointer") this.isClusterHover = true;
      else this.isClusterHover = false;
      e.map.getCanvas().style.cursor = style;
    },
    centerElement(e, zoom) {
      const features = e.component.getRenderedFeatures(e.mapboxEvent.point);
      // e.map represents the map
      e.map.easeTo({
        center: features[0].geometry.coordinates,
        ...(zoom && { zoom: zoom })
      });
    },
    showMapConsultationMarker(e) {
      if (this.currentConsultation.is_free_choice) {
        if (this.currentConsultation.restricted_area?.length) {
          if (this.isRestrictedAreaHover) {
            this.createMarker([
              e.mapboxEvent.lngLat.lng,
              e.mapboxEvent.lngLat.lat
            ]);
          } else {
            this.$toast.error(
              "Veuillez choisir un emplacement dans la zone dessinée à l'écran",
              {
                position: "top-center",
                timeout: 2794,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 1.92,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: false,
                icon: true,
                rtl: false
              }
            );
          }
        } else {
          this.createMarker([
            e.mapboxEvent.lngLat.lng,
            e.mapboxEvent.lngLat.lat
          ]);
        }
      }
      // this.showCustomMarker(e)
    },
    setRestrictedAreaHover(isHovered) {
      this.isRestrictedAreaHover = isHovered;
    }
  },
  watch: {
    isMapLoaded() {
      if (this.isMapLoaded) {
        if (
          this.currentConsultation.restricted_area &&
          this.currentConsultation.restricted_area.length
        ) {
          var bounds = this.currentConsultation.restricted_area.reduce(function(
            bounds,
            coord
          ) {
            return bounds.extend(coord);
          },
          new this.$refs["consultation-map"].mapbox.LngLatBounds(this.currentConsultation.restricted_area[0], this.currentConsultation.restricted_area[0]));

          this.$nextTick(() => {
            this.$refs["consultation-map"].map.fitBounds(bounds, {
              padding: 30
            });
          });
        }
        if (this.currentConsultation.consultation_stations.length) {
          if (this.currentConsultation.consultation_stations.length === 1) {
            let center = this.currentConsultation.consultation_stations[0].data
              .geometry.coordinates;
            this.$nextTick(() => {
              this.$refs["consultation-map"].map.flyTo(
                {
                  center: center,
                  zoom: 10
                },
                { duration: 300 }
              );
            });
          } else {
            let bboxArray = [];
            this.currentConsultation.consultation_stations.forEach(item => {
              let data = item.data.geometry.coordinates;
              bboxArray.push([data[0], data[1]]);
            });
            let line = turf.lineString(bboxArray);

            this.$nextTick(() => {
              this.$refs["consultation-map"].map.fitBounds(turf.bbox(line), {
                padding: 200
              });
            });
          }
        }
        if (!this.currentConsultation.is_free_choice) {
          setTimeout(() => {
            this.$refs["consultation-map"].map.setPaintProperty(
              "clusters-predef-mark",
              "circle-color",
              this.currentConsultation.colors.poi
                ? this.currentConsultation.colors.poi.hex
                : "#97C11E"
            );
            this.$refs["consultation-map"].map.setPaintProperty(
              "cluster-predef-mark-count",
              "text-color",
              this.currentConsultation.colors.text
                ? this.currentConsultation.colors.text.hex
                : "#ffffff"
            );
            this.$refs["consultation-map"].map.setPaintProperty(
              "predef-mark",
              "icon-color",
              this.currentConsultation.colors.poi
                ? this.currentConsultation.colors.poi.hex
                : "#97C11E"
            );
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.map-consultation {
  height: 100vh;
  width: 100%;
  .marker {
    background-image: url("~@/assets/map/marker.svg");
    background-size: contain;
    width: 50px;
    height: 50px;
    // border-radius: 50%;
    cursor: pointer;
    z-index: 1;
  }
  .left {
    min-width: 320px;
    width: 25%;
    margin-top: 80px;
    margin-left: 40px;
    position: absolute;
    .search {
      position: relative;
      margin-bottom: 10px;
      height: 39px;
      width: 100%;
      background: white;
      .introduction {
        height: 60vh;
        overflow: auto;
      }
      // border-radius: 3px;
    }
  }
  .message {
    //position: absolute;
    position: relative;
    z-index: 2;
    background: white;
    padding: 30px;
    text-align: center;
    font-size: 15px;
    border-radius: 6px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.33);
    &.header-message {
      text-align: left;
    }
  }
  @media screen and (max-width: 420px) {
    .left {
      width: 100%;
      margin-top: 0;
      margin-left: 0;
    }
  }
}
</style>
